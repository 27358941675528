// Variable overrides

$theme-colors: (
  "primary": #0077c0,
  "danger": #ff4136
);

.bf-content * {
  line-height: 1.4em;
};

.my-style {
  font-size: 1em;
  background-color: #ffffff;
}

.my-style-2 {
  font-size: 0.8em;
  background-color: #ffffff;

}


